import Users from '@/pages/admin/users/Users.vue'

const adminUsers= [

    {
      path: '/admin/users',
      name: 'users',
      component: Users,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Usuarios',
          }
        ]
      }
    },
  ]

  export default adminUsers