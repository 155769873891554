<template>
  <v-row>
    <v-col cols="12">
      <v-sheet 
        width="100%"
        outlined
        min-height="300px">
        <v-row class="pa-4">

          <v-col cols="12">
            <v-select
              :loading="loading"
              :items="editions"
              label="Edición"
              v-model="edition_id"
              name="editions"
              :item-text="'name'"
              :item-value="'id'"
              outlined
            ></v-select>
          </v-col>
        
          <v-col 
            cols="12"
            xl="4"
            md="6"
            sm="12"
            v-for="entrepreneurship in entrepreneurships"
            :key="entrepreneurship.id">
            <v-card
              class="d-flex flex-column mx-auto"
              outlined
              height="164"
              color="indigo lighten-5"
              >
              <v-progress-linear
                  :value="entrepreneurship.progress"
                  :color="entrepreneurshipStageColor(entrepreneurship.stage)"
                  height="8"
                  class="mr-2"
                  rounded
                ></v-progress-linear>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ entrepreneurshipStage(entrepreneurship.stage) }}
                  </v-list-item-subtitle>

                  <v-list-item-title>
                    <h4>{{ entrepreneurship.entrepreneurship.title }}</h4>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                
                <v-spacer></v-spacer>
                <v-btn
                  small
                  class="white--text"
                  color="primary"
                  @click="showTasks(entrepreneurship.entrepreneurship.slug)"
                >
                  Tareas
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-sheet>
    </v-col>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </v-row> 
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'EntrepreneurshipsAssignedEvaluator',

    data: () => ({
      loading:true,
      
      editions:[],
      edition_id:null,

      modules_assigned:[],
      module_pre_incubation:[],
      entrepreneurships:[]
    }),
    watch: {
      edition_id: {
        handler() {
          // Llama a la función getEntrepreneurships() cada vez que edition_id cambie
          this.loading = true
          this.getEntrepreneurships();
        },
        immediate: true // Para que se ejecute inmediatamente al cargar el componente
      }
    },
    methods:{
      entrepreneurshipStage(stage){
        let current_stage = 'PRE INCUBACIÓN'
        switch (stage) {
          case 'pre-incubation':
            current_stage = 'PRE INCUBACIÓN'
            break;
        
          case 'incubation':
            current_stage = 'INCUBACIÓN'
            break;

          case 'pre-aceleration':
            current_stage = 'PRE ACELERACIÓN'
            break;

          default:
            break;
        }
        return current_stage
      },

      entrepreneurshipStageColor(stage){
        let current_color = 'blue'
        switch (stage) {
          case 'pre-incubation':
            current_color = 'blue'
            break;
        
          case 'incubation':
            current_color = 'green'
            break;

          case 'pre-aceleration':
            current_color = 'red'
            break;

          default:
            break;
        }
        return current_color
      },
      
      async getEditions()
      {
        await axios.get('evaluator/editions').then(response =>{
          this.editions = response.data.data.editions
          
          if(this.editions && !this.edition_id){
            this.edition_id = this.editions[0].id
          }
        }).catch(error => {
          console.log(error);
          this.loading=false
        })
      },

      async getEntrepreneurships(){
        await axios.get('evaluator/entrepreneurships/'+this.edition_id+'/edition').then((response) =>{
          this.entrepreneurships = response.data.data.entrepreneurships
          this.loading=false;
        }).catch(error => {
          console.log(error);
        })
      },

      showTasks(slug){
        this.$router.push({ name: 'EntrepreneurshipTasksEvaluator', params: { slug: slug } })
      }
    },

    created(){
      this.getEditions()
    }
  }
</script>
<style>
.card-entreprenship {
  border: 10px solid red;
}
.zoom {
  cursor:pointer;
  padding: 50px;
  transition: transform .15s; /* Animation */
}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>