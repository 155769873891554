var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"width":"100%","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pa-8",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"pt-8",attrs:{"headers":_vm.headers,"items":_vm.tutors,"search":_vm.search,"custom-filter":_vm.customSearch,"items-per-page":30,"footer-props":{
                  'items-per-page-options': [10, 20, 30, 40, 50, 100],
                  'items-per-page-text':'Evaluadores por página',
                },"mobile":"true"},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastname.toUpperCase()+', '+item.name)+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.showTutor(item.id)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.moduleAssigned(item.id)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-view-module-outline ")])],1)]}}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"750px"},model:{value:(_vm.dialog_tutor),callback:function ($$v) {_vm.dialog_tutor=$$v},expression:"dialog_tutor"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Tutor")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("Apellido y Nombre:")]),_c('br'),_c('label',{staticClass:"pt-4"},[_vm._v(_vm._s(_vm.lastname.toUpperCase() + ', ' + _vm.name))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("Emprendimientos asignados:")]),_c('v-simple-table',{attrs:{"striped":""}},[_c('tbody',_vm._l((_vm.entrepreneurship_assigned_to_tutor),function(item){return _c('tr',{key:item},[_c('td',[_vm._v(_vm._s(item.entrepreneurship.title))])])}),0)])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_tutor = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-dialog',{attrs:{"overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_c('label',[_vm._v("Cargando...")]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }