<template>
  <v-row>

    <v-col cols="12">
      <v-sheet
        width="100%"
        outlined 
      >
        <v-row class="pa-4">

          <v-col class="pl-4" cols="12">
            <h3 class="font-weight-regular">Información de la edición:</h3>
          </v-col>

          <v-col
            cols="12">

            Nombre de la edición:
            <p class="font-weight-light text">
              {{ name }}
            </p>

            Descripción:
            <p class="font-weight-light text">
              {{ description }}
            </p>
          </v-col>

        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12" class="pt-2" v-if="!loading">
      <v-sheet
        width="100%"
        outlined 
      >
        <v-row class="pa-4">

          <v-col class="pl-4" cols="12">
            <h3 class="font-weight-regular">Editar edición:</h3>
          </v-col>

          <v-col
            cols="12"
            xl="6"
            md="6"
            sm="12"
            xs="12"
          >
            <v-btn 
              block 
              color="primary"
              :to="{ name: 'editInfoEdition' }">
              Información de la edición
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            xl="6"
            md="6"
            sm="12"
            xs="12"
          >
            <v-btn 
              block 
              color="primary"
              :to="{ name: 'editStagesEdition'}">
              Estadios
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            xl="6"
            md="6"
            sm="12"
            xs="12"
          >
            <v-btn 
              block 
              color="primary"
              :to="{ name: 'editConvocationEdition', params: { edition_id: this.edition_id }}">
              Convocatoria
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            xl="6"
            md="6"
            sm="12"
            xs="12"
          >
            <v-btn 
              block 
              color="primary"
              :to="{ name: 'editTermsConditionEdition', params: { edition_id: this.edition_id }}">
              Términos y condiciones
            </v-btn>
          </v-col>

        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12" class="pt-2 d-flex justify-space-between">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'editions'}"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>
    </v-col>

    <v-dialog
      v-model="error"
      persistent
      max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Advertencia
        </v-card-title>
        <v-card-text>
    
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title 
                v-for="error in errors" 
                v-bind:key="error"
                class="pa-2">
                - {{ error }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="error = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <label>Cargando...</label>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'editEdition',

    data: () => ({
      loading:true,
      edition_id:null,
      edition:null,

      name:'',
      description:'',
    }),
    methods:{
      async getEdition(){
        await axios.get('admin/editions/'+this.edition_id).then(response =>{
          this.edition = response.data.edition

          this.name = this.edition.name
          this.description = this.edition.description
          
          this.loading=false

        }).catch(error => {
          console.log(error);
        })
      }
    },

    created(){
      this.edition_id = this.$route.params.edition_id;
      this.getEdition()
    },
  }
</script>
