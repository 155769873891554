<template>
  <v-row>
    <v-col cols="12">
      <module v-if="!loading" :module="module" :stage="'pre-incubation'"/>
    </v-col>

    <v-col cols="12" class="pt-2 d-flex justify-space-between">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'modules'}"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>
      <v-spacer />
    </v-col>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
  import axios from 'axios';

  import module from '@/components/module/module.vue'

  export default {
    name: 'showModulePreIncubation',
    components: {
       module
    },
    data() {
      return {
        loading: true,

        edition_id:null,
        module_id: null,

        module:[]
      }
    },
    methods: {
      async getModule() {

        try {
          await axios.get('admin/edition/'+this.edition_id+'/modules-pre-incubation/'+this.module_id ).then(response => {
            this.module = response.data.data.module
            this.loading = false
          })
        } catch (error) {
          console.log(error);
        }
      }
    },
    created() {
      this.edition_id = this.$route.params.edition_id;
      this.module_id = this.$route.params.module_id
      this.getModule()
    }
  }
</script>