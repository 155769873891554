//*--------------Vistas del emprendedor -----------------*/

import myEntrepreneurship from '@/pages/entrepreneur/myEntrepreneurship/myEntrepreneurship.vue'
// import myStartUp from '@/pages/entrepreneur/myEntrepreneurship/myStartUp.vue'
// import myEntrepreneurialTeam from '@/pages/entrepreneur/myEntrepreneurship/myTeam.vue'
// import myWindow from '@/pages/entrepreneur/myEntrepreneurship/myWindow.vue'

// import editProfileEntrepreneur from '@/pages/entrepreneur/myEntrepreneurship/editProfile.vue'

// import Convocation from '@/pages/entrepreneur/convocation/Convocation.vue'
// import myIdea from '@/pages/entrepreneur/convocation/myIdea.vue'
// import showMyIdea from '@/pages/entrepreneur/convocation/showMyIdea.vue'
// import showMyTeam from '@/pages/entrepreneur/convocation/showMyTeam.vue'
// import myTeam from '@/pages/entrepreneur/convocation/myTeam.vue'

// import editProfile from '@/pages/entrepreneur/convocation/editProfile.vue'

//-------------------------------------------------------------------------
import myStartUp from '@/pages/entrepreneur/myEntrepreneurship/myStartUp.vue'
import myEntrepreneurialTeam from '@/pages/entrepreneur/myEntrepreneurship/myTeam.vue'
import myWindow from '@/pages/entrepreneur/myEntrepreneurship/myWindow.vue'

import editProfileEntrepreneur from '@/pages/entrepreneur/myEntrepreneurship/editProfile.vue'

import Convocation from '@/pages/entrepreneur/convocation/Convocation.vue'
import myIdea from '@/pages/entrepreneur/convocation/myIdea.vue'
import showMyIdea from '@/pages/entrepreneur/convocation/showMyIdea.vue'
import showMyTeam from '@/pages/entrepreneur/convocation/showMyTeam.vue'
import myTeam from '@/pages/entrepreneur/convocation/myTeam.vue'

import editProfile from '@/pages/entrepreneur/convocation/editProfile.vue'

import preIncubationEntrepreneurship from '@/pages/entrepreneur/edition/preIncubation/preIncubation.vue'
import pillsModule from '@/pages/entrepreneur/edition/preIncubation/pills/pills.vue'

import incubationEntrepreneurship from '@/pages/entrepreneur/edition/Incubation/Incubation.vue'
import pillsModuleIncubation from '@/pages/entrepreneur/edition/Incubation/pills/pills.vue'

import OtherEntrepreneurships from '@/pages/entrepreneur/otherEntrepreneurships/OtherEntrepreneurships.vue'
import showOtherEntrepreneurships from '@/pages/entrepreneur/otherEntrepreneurships/showOtherEntrepreneurships.vue'
import About from '@/pages/entrepreneur/about/About.vue'

//-------------------------------------------------------------------------

const entrepreneurRoutes = [
  //------------------------------------
  //---------- Convocatoria ------------
  //------------------------------------

  {
    path: '/convocation',
    name: 'convocation',
    component: Convocation,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Formulario de convocatoria'
        }
      ]
    }
  },
  {
    path: '/convocation/my-idea',
    name: 'my-idea',
    component: myIdea,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Formulario de convocatoria',
          to: { name: 'convocation' }
        },
        {
          text: 'Mi Idea'
        }
      ]
    }
  },
  {
    path: '/convocation/my-team',
    name: 'myTeam',
    component: myTeam,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Formulario de convocatoria',
          to: { name: 'convocation' }
        },
        {
          text: 'Mi Equipo'
        }
      ]
    }
  },
  {
    path: '/convocation/my-team/my-profile',
    name: 'editProfile',
    component: editProfile,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Formulario de convocatoria',
          to: { name: 'convocation' }
        },
        {
          text: 'Mi Equipo',
          to: { name: 'myTeam' }
        },
        {
          text: 'Mi perfíl'
        }
      ]
    }
  },

  
  {
    path: '/home/pre-incubation',
    name: 'preIncubationEntrepreneurship',
    component: preIncubationEntrepreneurship,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Pre Incubación'
        }
      ]
    }
  },
  {
    path: '/home/pre-incubation/module/:slug',
    name: 'pillsModule',
    component: pillsModule,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Pre Incubación',
          to: { name: 'preIncubationEntrepreneurship'}
        },
        {
          text: 'Módulo'
        }
      ]
    }
  },
  {
    path: '/home/incubation',
    name: 'incubationEntrepreneurship',
    component: incubationEntrepreneurship,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Incubación'
        }
      ]
    }
  },
  {
    path: '/home/incubation/module/:slug',
    name: 'pillsModuleIncubation',
    component: pillsModuleIncubation,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Incubación',
          to: { name: 'incubationEntrepreneurship'}
        },
        {
          text: 'Módulo'
        }
      ]
    }
  },

  {
    path: '/other-entrepreneurships',
    name: 'otherentrepreneurships',
    component: OtherEntrepreneurships,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Otros Emprendimientos'
        }
      ]
    }
  },
  {
    path: '/other-entrepreneurships/view/:slug',
    name: 'showotherentrepreneurships',
    component: showOtherEntrepreneurships,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Otros Emprendimientos',
          to: { name: 'otherentrepreneurships' }
        },
        {
          text: 'Ver'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Acerca de UNLPotencia'
        }
      ]
    }
  },
  
  {
    path: '/convocation/show-my-idea',
    name: 'showMyIdea',
    component: showMyIdea,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Formulario de convocatoria',
          to: { name: 'convocation' }
        },
        {
          text: 'Mi Idea'
        }
      ]
    }
  },
  {
    path: '/convocation/show-my-team',
    name: 'showMyTeam',
    component: showMyTeam,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Formulario de convocatoria',
          to: { name: 'convocation' }
        },
        {
          text: 'Mi equipo'
        }
      ]
    }
  },
  
  //------------------------------------
  //---------- Emprendimiento ----------
  //------------------------------------

  {
    path: '/my-entrepreneurship',
    name: 'myEntrepreneurship',
    component: myEntrepreneurship,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Mi Emprendimiento'
        }
      ]
    }
  },
  {
    path: '/my-entrepreneurship/my-startup',
    name: 'myStartUp',
    component: myStartUp,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Mi Emprendimiento',
          to: { name: 'myEntrepreneurship' }
        },
        {
          text: 'Mi Formulario de Inscripción'
        }
      ]
    }
  },
  {
    path: '/my-entrepreneurship/my-team',
    name: 'myEntrepreneurialTeam',
    component: myEntrepreneurialTeam,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Mi Emprendimiento',
          to: { name: 'myEntrepreneurship' }
        },
        {
          text: 'Mi Equipo'
        }
      ]
    }
  },
  {
    path: '/my-entrepreneurship/my-team/my-profile',
    name: 'editProfileEntrepreneur',
    component: editProfileEntrepreneur,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Mi Emprendimiento',
          to: { name: 'myEntrepreneurship' }
        },
        {
          text: 'Mi Equipo',
          to: { name: 'myEntrepreneurialTeam' }
        },
        {
          text: 'Mi Perfíl'
        }
      ]
    }
  },
  {
    path: '/my-entrepreneurship/my-window',
    name: 'myWindow',
    component: myWindow,
    meta: {
      auth: true,
      role: 'entrepreneur', 
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'home' }
        },
        {
          text: 'Mi Emprendimiento',
          to: { name: 'myEntrepreneurship' }
        },
        {
          text: 'Mi Stand'
        }
      ]
    }
  }
]

export default entrepreneurRoutes