import Config from '@/pages/admin/config/Config.vue'
import AcademicUnits from '@/pages/admin/config/AcademicUnits.vue'
import Careers from '@/pages/admin/config/Careers.vue'
import VerticalWorks from '@/pages/admin/config/VerticalWorks.vue'
import EntrepreneurshipNeed from '@/pages/admin/config/EntrepreneurshipNeed.vue'
import EntrepreneurshipCategories from '@/pages/admin/config/EntrepreneurshipCategories.vue'
import Provincias from '@/pages/admin/config/Provincias.vue'
import Localidades from '@/pages/admin/config/Localidades.vue'

const adminConfig= [
    {
      path: '/admin/config',
      name: 'config',
      component: Config,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración'
          }
        ]
      }
    },
    {
      path: '/admin/config/academic-units',
      name: 'academicUnits',
      component: AcademicUnits,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Unidades Académicas'
          }
        ]
      }
    },
    {
      path: '/admin/config/careers',
      name: 'careers',
      component: Careers,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Carreras'
          }
        ]
      }
    },
    {
      path: '/admin/config/vertical-works',
      name: 'verticalWorks',
      component: VerticalWorks,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Verticales de Trabajo'
          }
        ]
      }
    },
    {
      path: '/admin/config/entrepreneurship-needs',
      name: 'entrepreneurshipNeed',
      component: EntrepreneurshipNeed,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Necesidades de los Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/config/entrepreneurship-categories',
      name: 'EntrepreneurshipCategories',
      component: EntrepreneurshipCategories,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Categorias de los Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/config/provincias',
      name: 'provincias',
      component: Provincias,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Provincias'
          }
        ]
      }
    },
    {
      path: '/admin/config/localidades',
      name: 'localidades',
      component: Localidades,
      meta: {
        auth: true,
        role: 'admin',
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Localidades'
          }
        ]
      }
    }
  ]

  export default adminConfig