//*--------------Vistas del tutor -----------------*/
import EntrepreneurshipsAssignedTutor from '@/pages/tutor/EntrepreneurshipsAssigned.vue'
import EntrepreneurshipTutor from '@/pages/tutor/EntrepreneurshipTutor.vue'
// import EntrepreneurshipTasksModuleTutor from '@/pages/tutor/EntrepreneurshipTasksModule.vue'

import showStartUpTutor from '@/pages/tutor/show/showStartUp.vue'
import showStartUpTeamTutor from '@/pages/tutor/show/showTeam.vue'
import showStartupWindowTutor from '@/pages/tutor/show/showWindow.vue'

import showPreIncubationStageTutor from '@/pages/tutor/show/showPreIncubationStageTutor.vue'
import showPreIncubationStageTaskTutor from '@/pages/tutor/show/showPreIncubationStageTaskTutor.vue'

import showIncubationStageTutor from '@/pages/tutor/show/showIncubationStageTutor.vue'
import showIncubationStageTaskTutor from '@/pages/tutor/show/showIncubationStageTaskTutor.vue'
//--------------------------------------------------

const tutorRoutes = [
  {
    path: '/tutor/entrepreneurships-assigned',
    name: 'EntrepreneurshipsAssignedTutor',
    component: EntrepreneurshipsAssignedTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados'
        }
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug',
    name: 'EntrepreneurshipTutor',
    component: EntrepreneurshipTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas'
        }
      ]
    }
  },
  // {
  //   path: '/tutor/entrepreneurships-assigned/:slug/module/:module_pre_incubation_id/tasks',
  //   name: 'entrepreneurshipTasksModuleTutor',
  //   component: EntrepreneurshipTasksModuleTutor,
  //   meta: {
  //     auth: true,
  //     role: 'evaluator',
  //     breadCrumb: [
  //       {
  //         text: 'Emprendimientos asignados',
  //         to: { name: 'EntrepreneurshipsAssignedTutor'}
  //       },
  //       {
  //         text: 'Tareas',
  //         to: { name: 'EntrepreneurshipTutor'}
  //       },
  //       {
  //         text: 'Ver',
  //       }
  //     ]
  //   }
  // },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/startup',
    name: 'showStartUpTutor',
    component: showStartUpTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Ver StartUp',
        }
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/team',
    name: 'showStartUpTeamTutor',
    component: showStartUpTeamTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Ver equipo',
        }
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/window',
    name: 'showStartupWindowTutor',
    component: showStartupWindowTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Ver vidriera',
        }
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/incubation',
    name: 'showIncubationStageTutor',
    component: showIncubationStageTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Incubación',
        }
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/incubation/module/:module_incubation_id/tasks',
    name: 'showIncubationStageTaskTutor',
    component: showIncubationStageTaskTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Incubación',
          to: { name: 'showIncubationStageTutor'}
        },
        {
          text: 'Tarea'
        },
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/pre-incubation',
    name: 'showPreIncubationStageTutor',
    component: showPreIncubationStageTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Pre Incubación',
        }
      ]
    }
  },
  {
    path: '/tutor/entrepreneurships-assigned/:slug/pre-incubation/module/:module_pre_incubation_id/tasks',
    name: 'showPreIncubationStageTaskTutor',
    component: showPreIncubationStageTaskTutor,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedTutor'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTutor'}
        },
        {
          text: 'Pre Incubación',
          to: { name: 'showPreIncubationStageTutor'}
        },
        {
          text: 'Tarea'
        },
      ]
    }
  },
  
]

export default tutorRoutes