import Tutors from '@/pages/admin/tutor/tutors.vue'
import EntrepreneurshipsAssignedTutors from '@/pages/admin/tutor/moduleAssigned.vue'

const adminTutors= [
  {
    path: '/admin/tutors',
    name: 'tutors',
    component: Tutors,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Tutores',
        }
      ]
    }
  },
  {
    path: '/admin/tutors/module-assigned/:tutor_id',
    name: 'entrepreneurshipsAssignedTutors',
    component: EntrepreneurshipsAssignedTutors,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Tutores',
          to: { name: 'tutors' }
        },
        {
          text: 'Proyectos asignados'
        }
      ]
    }
  },
]

export default adminTutors