//*--------------Vistas del evaluador -----------------*/
import EntrepreneurshipsAssignedEvaluator from '@/pages/evaluator/EntrepreneurshipsAssigned.vue'
import EntrepreneurshipTasksEvaluator from '@/pages/evaluator/EntrepreneurshipTasks.vue'

import showStartUpEvaluator from '@/pages/evaluator/show/showStartUp.vue'
import showStartUpTeamEvaluator from '@/pages/evaluator/show/showTeam.vue'
import showStartupWindowEvaluator from '@/pages/evaluator/show/showWindow.vue'
import showPreIncubationStageEvaluator from '@/pages/evaluator/show/showPreIncubationStageEvaluator.vue'
import showPreIncubationStageTaskEvaluator from '@/pages/evaluator/show/showPreIncubationStageTaskEvaluator.vue'

import showIncubationStageEvaluator from '@/pages/evaluator/show/showIncubationStageEvaluator.vue'
import showIncubationStageTaskEvaluator from '@/pages/evaluator/show/showIncubationStageTaskEvaluator.vue'

import Modules from '@/pages/evaluator/modules/Modules.vue'
import showModulePreIncubationEvaluator from '@/pages/evaluator/modules/showModulePreIncubationEvaluator.vue'
import showModuleIncubationEvaluator from '@/pages/evaluator/modules/showModuleIncubationEvaluator.vue'

//--------------------------------------------------

const evaluatorRoutes = [
  {
    path: '/evaluator/entrepreneurships-assigned',
    name: 'EntrepreneurshipsAssignedEvaluator',
    component: EntrepreneurshipsAssignedEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados'
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug',
    name: 'EntrepreneurshipTasksEvaluator',
    component: EntrepreneurshipTasksEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas'
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/startup',
    name: 'showStartUpEvaluator',
    component: showStartUpEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Ver StartUp',
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/team',
    name: 'showStartUpTeamEvaluator',
    component: showStartUpTeamEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Ver equipo',
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/window',
    name: 'showStartupWindowEvaluator',
    component: showStartupWindowEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Ver vidriera',
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/pre-incubation',
    name: 'showPreIncubationStageEvaluator',
    component: showPreIncubationStageEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Pre Incubación',
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/pre-incubation/module/:module_pre_incubation_id/tasks',
    name: 'showPreIncubationStageTaskEvaluator',
    component: showPreIncubationStageTaskEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Pre Incubación',
          to: { name: 'showPreIncubationStageEvaluator'}
        },
        {
          text: 'Ver',
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/incubation',
    name: 'showIncubationStageEvaluator',
    component: showIncubationStageEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Incubación',
        }
      ]
    }
  },
  {
    path: '/evaluator/entrepreneurships-assigned/:slug/incubation/module/:module_incubation_id/tasks',
    name: 'showIncubationStageTaskEvaluator',
    component: showIncubationStageTaskEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Emprendimientos asignados',
          to: { name: 'EntrepreneurshipsAssignedEvaluator'}
        },
        {
          text: 'Tareas',
          to: { name: 'EntrepreneurshipTasksEvaluator'}
        },
        {
          text: 'Incubación',
          to: { name: 'showIncubationStageEvaluator'}
        },
        {
          text: 'Ver',
        }
      ]
    }
  },
  // {
  //   path: '/evaluator/modules',
  //   name: 'Modules',
  //   component: Modules,
  //   meta: {
  //     auth: true,
  //     role: 'evaluator',
  //     breadCrumb: [
  //       {
  //         text: 'Módulos'
  //       }
  //     ]
  //   }
  // },
  {
    path: '/evaluator/modules',
    name: 'Modules',
    component: Modules,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Módulos'
        }
      ]
    }
  },
  {
    path: '/evaluator/modules/pre-incubation/:slug',
    name: 'showModulePreIncubationEvaluator',
    component: showModulePreIncubationEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Módulos',
          to: { name: 'Modules'}
        },
        {
          text: 'Ver'
        }
      ]
    }
  },
  {
    path: '/evaluator/modules/incubation/:slug',
    name: 'showModuleIncubationEvaluator',
    component: showModuleIncubationEvaluator,
    meta: {
      auth: true,
      role: 'evaluator',
      breadCrumb: [
        {
          text: 'Módulos',
          to: { name: 'Modules'}
        },
        {
          text: 'Ver'
        }
      ]
    }
  }
]

export default evaluatorRoutes