var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":require('@/assets/img/potencia.png'),"contain":"","max-height":"540"}})],1),_c('v-col',{staticClass:"mt-8 pt-8 pb-8",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"2","md":"2","sm":"4","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"136"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/home_accepted/postulation_idea.png')}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":_vm.show1}},[_vm._v(" Ver más ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","md":"2","sm":"4","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"136"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/home_accepted/feedback.png')}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":_vm.show2}},[_vm._v(" Ver más ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","md":"2","sm":"4","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"136"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/home_accepted/selection.png')}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":_vm.show3}},[_vm._v(" Ver más ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","md":"2","sm":"4","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"136"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/home_accepted/pre_incubation.png')}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[(_vm.edition.pre_incubation)?_c('v-btn',{on:{"click":function($event){return _vm.$router.push('/home/pre-incubation')}}},[_vm._v(" Ver más ")]):_vm._e(),(!_vm.edition.pre_incubation)?_c('v-btn',{on:{"click":_vm.show4}},[_vm._v(" Ver más ")]):_vm._e()],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","md":"2","sm":"4","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"136"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/home_accepted/incubation.png')}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[(_vm.edition.incubation)?_c('v-btn',{on:{"click":function($event){return _vm.$router.push('/home/incubation')}}},[_vm._v(" Ver más ")]):_vm._e(),(!_vm.edition.incubation)?_c('v-btn',{on:{"click":_vm.show5}},[_vm._v(" Ver más ")]):_vm._e()],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","md":"2","sm":"4","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"136"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/home_accepted/pre_aceleration.png')}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":_vm.show6}},[_vm._v(" Ver más ")])],1):_vm._e()],1)],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.messange)+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-dialog',{attrs:{"overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_c('label',[_vm._v("Cargando...")]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }