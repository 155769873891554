//*--------------Vistas del administrador -----------------*/
import Editions from '@/pages/admin/editions/Editions.vue'
import showEdition from '@/pages/admin/editions/showEdition.vue'
import editEdition from '@/pages/admin/editions/editEdition.vue'
import editInfoEdition from '@/pages/admin/editions/editInfoEdition.vue'
import editStagesEdition from '@/pages/admin/editions/editStagesEdition.vue'
import editConvocationEdition from '@/pages/admin/editions/editConvocationEdition.vue'
import editTermsConditionEdition from '@/pages/admin/editions/editTermsConditionEdition.vue'


import entrepreneurshipsEdition from '@/pages/admin/editions/entrepreneurships/entrepreneurshipsEdition.vue'
import newEntrepreneurshipEdition from '@/pages/admin/editions/entrepreneurships/newEntrepreneurshipEdition.vue'
import showConvocationFormEdition from '@/pages/admin/editions/entrepreneurships/show/showConvocationFormEdition.vue'
import showEntrepreneurshipsEdition from '@/pages/admin/editions/entrepreneurships/show/showEntrepreneurshipsEdition.vue'

import showConvocationFormAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showConvocationFormAccepted.vue'
import showEntrepreneurshipAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showEntrepreneurshipAccepted.vue'
import showTeamAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showTeamAccepted.vue'
import showWindowAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showWindowAccepted.vue'
import showModulesPreIncubations from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showModulesPreIncubations.vue'
import showModulePreIncubation from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showModulePreIncubation.vue'

// import preIncubation from'@/pages/admin/editions/stages/preIncubation/modules/Modules.vue'
import modules from'@/pages/admin/editions/stages/preIncubation/modules/Modules.vue'
import showModule from '@/pages/admin/editions/stages/preIncubation/modules/show.vue'
import newModule from '@/pages/admin/editions/stages/preIncubation/modules/newModule.vue'
import editModule from '@/pages/admin/editions/stages/preIncubation/modules/editModule.vue'

import pills from'@/pages/admin/editions/stages/preIncubation/pills/pills.vue'
import newPill from'@/pages/admin/editions/stages/preIncubation/pills/newPill.vue'
import showPill from'@/pages/admin/editions/stages/preIncubation/pills/showPill.vue'
import editPill from'@/pages/admin/editions/stages/preIncubation/pills/editPill.vue'

import modulesIncubation from'@/pages/admin/editions/stages/Incubation/modules/ModulesIncubation.vue'
import showModuleIncubation from '@/pages/admin/editions/stages/Incubation/modules/showModuleIncubation.vue'
import newModuleIncubation from '@/pages/admin/editions/stages/Incubation/modules/newModuleIncubation.vue'
import editModuleIncubation from '@/pages/admin/editions/stages/Incubation/modules/editModuleIncubation.vue'

import pillsIncubation from'@/pages/admin/editions/stages/Incubation/pills/pillsIncubation.vue'
import newPillIncubation from'@/pages/admin/editions/stages/Incubation/pills/newPillIncubation.vue'
import showPillIncubation from'@/pages/admin/editions/stages/Incubation/pills/showPillIncubation.vue'
import editPillIncubation from'@/pages/admin/editions/stages/Incubation/pills/editPillIncubation.vue'

import modulesAssignedToEntrepreneurships from'@/pages/admin/editions/stages/Incubation/modulesAssignedToEntrepreneurships/modulesAssignedToEntrepreneurships.vue'

const adminRoutes= [
      
    {
      path: '/admin/editions',
      name: 'editions',
      component: Editions,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id',
      name: 'showEdition',
      component: showEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit',
      name: 'editEdition',
      component: editEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/info-edition',
      name: 'editInfoEdition',
      component: editInfoEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Información'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages',
      name: 'editStagesEdition',
      component: editStagesEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/convocation',
      name: 'editConvocationEdition',
      component: editConvocationEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Convocatoria'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/terms-conditions',
      name: 'editTermsConditionEdition',
      component: editTermsConditionEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Términos y condiciones'
          }
        ]
      }
    },
    
    {
      path: '/admin/editions/:edition_id/entrepreneurships',
      name: 'entrepreneurshipsEdition',
      component: entrepreneurshipsEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/new',
      name: 'newEntrepreneurshipEdition',
      component: newEntrepreneurshipEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition'}
          },
          {
            text: 'Nuevo'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/convocation-form/:convocation_form_id',
      name: 'showConvocationFormEdition',
      component: showConvocationFormEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id',
      name: 'showEntrepreneurshipsEdition',
      component: showEntrepreneurshipsEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/convocation-form/:convocation_form_id',
      name: 'showConvocationFormAccepted',
      component: showConvocationFormAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Formularaio de convocatoria'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-entrepreneurship',
      name: 'showEntrepreneurshipAccepted',
      component: showEntrepreneurshipAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'StartUp'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-team/:convocation_form_id',
      name: 'showTeamAccepted',
      component: showTeamAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Equipo emprendedor'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-window/:convocation_form_id',
      name: 'showWindowAccepted',
      component: showWindowAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Vidriera'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-modules-pre-incubation',
      name: 'showModulesPreIncubations',
      component: showModulesPreIncubations,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Modulos pre-incubación'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-modules-pre-incubation/:module_pre_incubation_id',
      name: 'showModulePreIncubation',
      component: showModulePreIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Modulos pre-incubación',
            to: { name: 'showModulesPreIncubations' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation',
      name: 'modules',
      component: modules,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/:module_id',
      name: 'showModule',
      component: showModule,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Ver',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/new-module',
      name: 'newModule',
      component: newModule,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Nuevo',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/:module_id/edit',
      name: 'editModule',
      component: editModule,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Editar',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/:module_id/pills',
      name: 'pills',
      component: pills,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/:module_id/pills/new',
      name: 'newPill',
      component: newPill,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pills' }
          },
          {
            text: 'Nueva',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/:module_id/pills/show/:pill_id',
      name: 'showPill',
      component: showPill,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pills' }
          },
          {
            text: 'Ver',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/pre-incubation/:module_id/pills/edit/:pill_id',
      name: 'editPill',
      component: editPill,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pills' }
          },
          {
            text: 'Editar',
          }
        ]
      }
    },

    {
      path: '/admin/editions/:edition_id/edit/stages/incubation',
      name: 'modulesIncubation',
      component: modulesIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id',
      name: 'showModuleIncubation',
      component: showModuleIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Ver',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/new-module',
      name: 'newModuleIncubation',
      component: newModuleIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Nuevo',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id/edit',
      name: 'editModuleIncubation',
      component: editModuleIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Editar',
          }
        ]
      }
    },


    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id/pills',
      name: 'pillsIncubation',
      component: pillsIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id/pills/new',
      name: 'newPillIncubation',
      component: newPillIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pillsIncubation' }
          },
          {
            text: 'Nueva',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id/pills/show/:pill_id',
      name: 'showPillIncubation',
      component: showPillIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pillsIncubation' }
          },
          {
            text: 'Ver',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id/pills/edit/:pill_id',
      name: 'editPillIncubation',
      component: editPillIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pillsIncubation' }
          },
          {
            text: 'Editar',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/stages/incubation/:module_id/modules-assigned-entrepreneurships',
      name: 'modulesAssignedToEntrepreneurships',
      component: modulesAssignedToEntrepreneurships,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Estadios',
            to: { name: 'editStagesEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Asignación de modulos',
          }
        ]
      }
    },
  ]

  export default adminRoutes