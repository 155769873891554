<template>
  <v-row>
    <v-col cols="12">

      <v-sheet
        width="100%"
        outlined 
      >
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" width="10%">
                      #ID
                    </th>
                    <th class="text-center" width="70%">
                      Emprendimiento
                    </th>
                    <th class="text-center" width="20%">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in entrepreneurships"
                    :key="item.id"
                  >
                    <td class="text-center">{{ item.id }}</td>
                    <td>{{ item.title }}</td>
                    <td class="text-center d-flex justify-center">
                      <v-switch
                        v-model="item.assigned"
                        @click="handdleAssignedModules(item.id, item.assigned)"
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="pt-8 d-flex justify-space-between">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'modulesIncubation'}"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>
    </v-col>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <label>Cargando...</label>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </v-row>
</template>
<script>
  import axios from 'axios'

  export default {
    name: 'modulesAssignedToEntrepreneurships',

    data: () => ({
      loading:true,
      show:false,
      switch: false,
      edition_id:null,
      module_id: null,

      entrepreneurships:[],
    }),

    methods:{
      async getIncuvationModules(){
        await axios.get('admin/edition/'+this.edition_id+'/modules-incubation/'+this.module_id+'/modules-assigned-entrepreneurships').then(response =>{
          this.entrepreneurships = response.data.data.entrepreneurships
          this.entrepreneurships.switch=false
          this.loading=false
          this.show=true
        }).catch(error => {
          console.log(error);
        })
      },

      async handdleAssignedModules(id, assigned){
        this.loading=true
        await axios.put('admin/edition/'+this.edition_id+'/modules-incubation/'+this.module_id+'/assigned-module',{
          'action': (assigned)?'add':'remove',
          'entrepreneurship_id': id,
          'module_id': this.module_id
        }).then( (response) =>{
          this.entrepreneurships = response.data.data.entrepreneurships
          this.loading=false
        }).catch(error => {
          console.log(error);
        })
      }
    },
    created(){
      this.edition_id = this.$route.params.edition_id
      this.module_id = this.$route.params.module_id
      this.getIncuvationModules()
    },
  }
</script>