<template>
  <v-row>
    <v-col cols="12" class="mt-4">
      <h2 class="font-weight-light pl-2 pb-4">Información</h2>
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12" class="mt-4">
      <h2 class="font-weight-regular pl-2">{{ module.name }}</h2>
      <p class="text-pre-wrap font-weight-light pl-2 pt-3">{{ module.description }}</p>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    name: 'infoModule',
    props:['module'],
  }
</script>