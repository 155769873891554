/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'

import adminConfig from '@/routes/admin/adminConfig'
import adminEdition from '@/routes/admin/adminEdition'
import adminEntrepreneurships from '@/routes/admin/adminEntrepreneurships'
import adminEntrepreneurs from '@/routes/admin/adminEntrepreneurs'
import adminEvaluators from '@/routes/admin/adminEvaluators'
import adminTutors from '@/routes/admin/adminTutors'
import adminUsers from '@/routes/admin/adminUsers'

import entrepreneurRoutes from '@/routes/entrepreneur'
import tutorRoutes from '@/routes/tutor'
import evaluatorRoutes from '@/routes/evaluator'

import CheckConvocations from '@/pages/CheckConvocations.vue'
import Home from '@/pages/entrepreneur/Home.vue'


import NotFound from '@/pages/NotFoundPage.vue'
import UNLPotencia from '@/pages/UNLPotencia/UNLPotencia.vue'
import Login from '@/pages/auth/Login.vue'
import PasswordRecovery from '@/pages/auth/PasswordRecovery.vue'
import setPasswordRecovery from '@/pages/auth/setPasswordRecovery.vue'
import Register from '@/pages/auth/Register.vue'
import Invitation from '@/pages/auth/Invitation.vue'
import InvitationVip from '@/pages/auth/InvitationVip.vue'

import Profile from '@/pages/profile/Profile.vue'

Vue.use(VueRouter)

const routes2= [
    {
      path: '/',
      name: 'UNLPotencia',
      component: UNLPotencia
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/password-recovery',
      name: 'PasswordRecovery',
      component: PasswordRecovery
    },
    {
      path: '/password-recovery/:url',
      name: 'setPasswordRecovery',
      component: setPasswordRecovery
    },
    {
      path: '/register/:slug',
      name: 'register',
      component: Register
    },
    {
      path: '/check-convocations',
      name: 'CheckConvocations',
      component: CheckConvocations,
      meta: {
        auth: true
      }
    },
    {
      path: '/invitation/:url',
      name: 'Invitation',
      component: Invitation
    },
    {
      path: '/invitation-vip/:url',
      name: 'InvitationVip',
      component: InvitationVip
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        auth: true,
        role: 'entrepreneur', 
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'home' }
          },
          {
            text: 'Editar cuenta'
          }
        ]
      }
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
        role: 'entrepreneur', 
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'home' }
          }
        ]
      }
    },
    
    { path: '*', component: NotFound }
  ]

  const routes = routes2.concat(
                                  adminConfig, 
                                  adminEdition,
                                  adminEntrepreneurships,
                                  adminEntrepreneurs,
                                  adminEvaluators,
                                  adminTutors,
                                  adminUsers,
                                  entrepreneurRoutes, 
                                  tutorRoutes, 
                                  evaluatorRoutes 
                                )

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      // always scroll to top
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
  })

  router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
      next('/login')
      return
    }
    next()
  })
  
  export default router