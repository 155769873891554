<template>
  <v-row>
    <v-col cols="12" class="mt-4">
      <h2 class="font-weight-light pl-2 pb-4">Actividad</h2>
      <v-divider></v-divider>
    </v-col>
    
    <v-col cols="12">
      <p class="text-pre-wrap font-weight-light pa-2">{{ module.activity_statement }}</p>
    </v-col>

      <v-row class="pt-2 pb-8" v-if="!module.activity_statement">
        <v-col cols="12" class="text-center pt-8">
          <v-icon
            x-large
          >
            mdi-timer-sand-empty
          </v-icon>
          
          <h1 class="font-weight-light pt-8 pb-8">
            El administrador aún no subió la actividad de este módulo.
          </h1>
        </v-col>
      </v-row>


  </v-row>
</template>
<script>
  import axios from 'axios';
  import download from 'downloadjs'

  const APP_URL  = process.env.VUE_APP_URL 

  export default {
    name: 'taskModule',
    props:['module'],
    data() {
      return {
        loading:false,
        upload_task:false,
        send_task:false,
        loading_task:false,
        dialog_errors:false,
        active_upload_task:false,
        tasks:[],
        task:null,
        message:null,
        messageRules: [
          v => (v && v.length <= 99) || 'El mensaje no debe tener mas de 100 caracteres',
        ],
        errors:[]
      }
    },
    methods: {

      async donwloadTask(url){
        this.loading_task=true
        await axios.get(
          APP_URL + url, 
          {responseType: 'blob'}
        ).then((response) => {
          console.log(response)
          const content = response.headers['content-type'];
          download(response.data, 'Entrega.pdf', content)
          this.loading_task=false
        })
      }
    },
    created(){
      // this.getTasks()
    },
  }
</script>