import Entrepreneurships from '@/pages/admin/entrepreneurships/Entrepreneurships.vue'
import showEntrepreneurships from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurships.vue'
import showEntrepreneurshipsConvocationForm from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsConvocationForm'
import showEntrepreneurshipsStartup from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsStartup'
import showEntrepreneurshipsTeam from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsTeam'
import showEntrepreneurshipsWindow from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsWindow.vue'
import showEntrepreneurshipsWindowWall from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsWindowWall.vue'

import showPreIncubationStageAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showPreIncubationStageAdmin.vue'
import showPreIncubationStageTaskAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showPreIncubationStageTaskAdmin.vue'

import showIncubationStageAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/incubation/showIncubationStageAdmin.vue'
import showIncubationStageTaskAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/incubation/showIncubationStageTaskAdmin.vue'

// import showEntrepreneurshipsModulePreIncubation from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showModulePreIncubation.vue'
// import showEntrepreneurshipsModulesPreIncubation from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showModulesPreIncubations.vue'

const adminEntrepreneurships= [
  {
    path: '/admin/entrepreneurships',
    name: 'entrepreneurships',
    component: Entrepreneurships,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos'
        }
      ]
    }
  },
  {
    path: '/admin/entrepreneurships/:id',
    name: 'showEntrepreneurships',
    component: showEntrepreneurships,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver'
        }
      ]
    }
  },
  {
    path: '/admin/entrepreneurships/:id/convocation-form',
    name: 'showEntrepreneurshipsConvocationForm',
    component: showEntrepreneurshipsConvocationForm,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'Formulario de la convocatoria'
        }
      ]
    }
  },
  {
    path: '/admin/entrepreneurships/:id/startup',
    name: 'showEntrepreneurshipsStartup',
    component: showEntrepreneurshipsStartup,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'StartUp'
        }
      ]
    }
  },
  {
    path: '/admin/entrepreneurships/:id/team',
    name: 'showEntrepreneurshipsTeam',
    component: showEntrepreneurshipsTeam,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'Integrantes'
        }
      ]
    }
  },
  {
    path: '/admin/entrepreneurships/:id/window',
    name: 'showEntrepreneurshipsWindow',
    component: showEntrepreneurshipsWindow,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'Ver vidriera'
        }
      ]
    }
  },
  {
    path: '/admin/entrepreneurships/:id/window-wall',
    name: 'showEntrepreneurshipsWindowWall',
    component: showEntrepreneurshipsWindowWall,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Vidriera'
        }
      ]
    }
  },

  {
    path: '/admin/edition/:edition_id/entrepreneurships/:id/pre-incubation',
    name: 'showPreIncubationStageAdmin',
    component: showPreIncubationStageAdmin,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'Pre Incubación'
        }
      ]
    }
  },
  

  {
    path: '/admin/entrepreneurships/:entrepreneurship_id/pre-incubation/:module_pre_incubation_id',
    name: 'showPreIncubationStageTaskAdmin',
    component: showPreIncubationStageTaskAdmin,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'Pre incubación'
        }
      ]
    }
  },

  {
    path: '/admin/entrepreneurships/:id/incubation',
    name: 'showIncubationStageAdmin',
    component: showIncubationStageAdmin,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'Incubación'
        }
      ]
    }
  },
  

  {
    path: '/admin/entrepreneurships/:entrepreneurship_id/incubation/:module_incubation_id',
    name: 'showIncubationStageTaskAdmin',
    component: showIncubationStageTaskAdmin,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendimientos',
          to: { name: 'entrepreneurships' }
        },
        {
          text: 'Ver',
          to: { name: 'showEntrepreneurships' }
        },
        {
          text: 'incubación'
        }
      ]
    }
  },
  
  // {
  //   path: '/admin/entrepreneurships/:id/modules-incubation',
  //   name: 'showEntrepreneurshipsModulesIncubation',
  //   component: showEntrepreneurshipsModulesIncubation,
  //   meta: {
  //     auth: true,
  //     role: 'admin', 
  //     breadCrumb: [
  //       {
  //         text: 'Emprendimientos',
  //         to: { name: 'entrepreneurships' }
  //       },
  //       {
  //         text: 'Ver',
  //         to: { name: 'showEntrepreneurships' }
  //       },
  //       {
  //         text: 'Incubación'
  //       }
  //     ]
  //   }
  // },
  
  ]

  export default adminEntrepreneurships