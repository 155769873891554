import Evaluators from '@/pages/admin/evaluator/Evaluators.vue'
import ModuleAssigned from '@/pages/admin/evaluator/moduleAssigned.vue'

const adminEvaluators= [
  {
    path: '/admin/evaluators',
    name: 'evaluators',
    component: Evaluators,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Evaluadores',
        }
      ]
    }
  },
  {
    path: '/admin/evaluators/module-assigned/:evaluator_id',
    name: 'moduleAssigned',
    component: ModuleAssigned,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Evaluadores',
          to: { name: 'evaluators' }
        },
        {
          text: 'Proyectos asignados'
        }
      ]
    }
  }
]

export default adminEvaluators