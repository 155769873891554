<template>
  <v-row>

    <v-col cols="12" class="pt-2" v-if="!loading">
      <v-sheet
        width="100%"
        outlined 
      >
        <v-row class="pa-4">

          <v-col class="pl-4" cols="12">
            <h3 class="font-weight-regular">Términos y condiciones:</h3>
          </v-col>

          <v-col
            cols="12"
          >            
            <v-textarea
              height="400"
              outlined
              ref="terms_and_conditions"
              v-model="terms_and_conditions"
              name="terms_and_conditions"
              label="Terminos y condiciones"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12" class="pt-2 d-flex justify-space-between">
      <v-btn
        depressed
        color="primary"
        :disabled="loading_update"
        :to="{ name: 'editEdition'}"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>

      <v-btn
        depressed
        :loading="loading_update"
        color="primary"
        @click="updateEdition()"
        >
        Guardar
      </v-btn>
    </v-col>

    <v-dialog
      v-model="error"
      persistent
      max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Advertencia
        </v-card-title>
        <v-card-text>
    
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title 
                v-for="error in errors" 
                v-bind:key="error"
                class="pa-2">
                - {{ error }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="error = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <label>Cargando...</label>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'editTermsConditionEdition',

    data: () => ({
      loading:true,
      loading_update:false,
      edition_id:null,
      edition:null,

      terms_and_conditions:null,

      error:false,
      errors:[],
    }),
    methods:{
      async getEdition(){
        await axios.get('admin/editions/'+this.edition_id).then(response =>{
          this.edition = response.data.edition

          this.terms_and_conditions= this.edition.terms_and_conditions
          
          this.loading=false
          this.show=true

        }).catch(error => {
          console.log(error);
        })
      },

      dateConvert(date){
        const parts = date.split(/[- :]/);
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
      },

      async updateEdition(){
        this.loading_update=true
        this.error = false
        this.errors = []

        try {
          await axios.put('admin/editions/'+this.edition_id+'/terms-conditions',{
            terms_and_conditions: this.terms_and_conditions
            }).then(() =>{
              if(this.errors.length===0){
                this.$router.push({ name: 'editEdition' })  
              }
              })
          } 
        catch (error) {
          //console.error(error.response.data);     // NOTE - use "error.response.data` (not "error")
          Object.values(error.response.data.errors).forEach(error => {
            this.errors.push(error[0])
          })
        }

        if(this.errors.length!=0){
          this.loading_update=false
          this.error=true
        }
      }
    },

    created(){
      this.edition_id = this.$route.params.edition_id;
      this.getEdition()
    },
  }
</script>
