<template>
  <v-row>

    <v-col cols="12" class="pt-2">
      <v-sheet
        width="100%"
        outlined 
      >
        <v-row class="pa-4">

          <v-col class="pl-4" cols="12">
            <h3 class="font-weight-regular">Estadios:</h3>
          </v-col>

          <v-col 
            cols="12"
            xl="4"
            md="4"
            sm="12"
            xs="12">
            <v-sheet
              outlined class="pa-2">

              <v-switch
                v-model="pre_incubation"
                label="Pre incubación"
                @click="updateStageEdition()"
              ></v-switch>
              <v-btn 
                block
                class="mt-4"
                color="primary"
                :disabled="!edition.pre_incubation"
                :to="{ name: 'modules', params: { id: this.edition_id }}">
                Pre Incubación
              </v-btn>
            </v-sheet>
          </v-col>

          <v-col 
            cols="12"
            xl="4"
            md="4"
            sm="12"
            xs="12">
            <v-sheet
              outlined class="pa-2">

              <v-switch
                v-model="incubation"
                label="Incubación"
                @click="updateStageEdition()"
              ></v-switch>
              <v-btn 
                block 
                class="mt-4"
                color="primary" 
                :disabled="!edition.incubation"
                :to="{ name: 'modulesIncubation', params: { id: this.edition_id }}">
                Incubación
              </v-btn>
            </v-sheet>
          </v-col>

          <v-col 
            cols="12"
            xl="4"
            md="4"
            sm="12"
            xs="12">
            <v-sheet
              outlined class="pa-2">

              <v-switch
                v-model="pre_aceleration"
                label="Pre aceleración"
                @click="updateStageEdition()"
              ></v-switch>
              <v-btn 
                block 
                class="mt-4"
                color="primary" 
                :disabled="!edition.pre_aceleration">
                Pre Aceleración
              </v-btn>
            </v-sheet>
          </v-col>

        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12" class="pt-2 d-flex justify-space-between">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'editEdition'}"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>
    </v-col>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <label>Cargando...</label>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'editStagesEdition',

    data: () => ({
      loading:true,
      edition_id:null,
      edition:[],

      pre_incubation: false,
      incubation: false,
      pre_aceleration: false
   }),
    methods:{
      async getEdition(){
        await axios.get('admin/editions/'+this.edition_id).then(response =>{
          this.edition = response.data.edition
          
          this.pre_incubation = (this.edition.pre_incubation)?true:false
          this.incubation = (this.edition.incubation)?true:false
          this.pre_aceleration = (this.edition.pre_aceleration)?true:false
          
          this.loading=false

        }).catch(error => {
          console.log(error);
        })
      },

      async updateStageEdition(){
        this.loading=true

        try {
          await axios.put('admin/editions/'+this.edition_id+'/stage',{
            pre_incubation: (this.pre_incubation)?1:0, 
            incubation: (this.incubation)?1:0, 
            pre_aceleration: (this.pre_aceleration)?1:0,
            }).then((response) =>{
              this.edition = response.data.data.edition
              
              this.pre_incubation = (this.edition.pre_incubation)?true:false
              this.incubation = (this.edition.incubation)?true:false
              this.pre_aceleration = (this.edition.pre_aceleration)?true:false

              this.loading=false
            })
          } 
          catch (error) {
          console.error(error.response.data);     // NOTE - use "error.response.data` (not "error")
          
        }
      }
    },

    created(){
      this.edition_id = this.$route.params.edition_id;
      this.getEdition()
    },
  }
</script>
