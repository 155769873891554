<template>
  <v-row>
    <v-col cols="12" class="mt-4">
      <h2 class="font-weight-light pl-2 pb-4">Emprendimientos asociados</h2>
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12" class="mt-4">
      <h3 class="font-weight-light pl-2"><b> Módulo:</b> {{ module.name }}</h3>
    </v-col>
    
    <v-col class="mb-8">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">
              Emprendimientos asociados
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in module.entrepreneurships"
            :key="item.id"
          >
            <td>{{ item.title }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>

  </v-row>
</template>
<script>
  export default {
    name: 'entrepreneurshipsModule',
    props:['module'],
  }
</script>