var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"ma-4",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"pt-8",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.entrepreneurships,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"custom-filter":_vm.customSearch,"items-per-page":30,"header-props":_vm.headerProps,"footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50, 100],
        'items-per-page-text':'Emprendedores por página',
      }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.progress",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.progress)+"% ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.showItem(item.id)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }