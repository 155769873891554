import Entrepreneurs from '@/pages/admin/entrepreneurs/Entrepreneurs.vue'

const adminEntrepreneurs= [
  {
    path: '/admin/entrepreneurs',
    name: 'entrepreneurs',
    component: Entrepreneurs,
    meta: {
      auth: true,
      role: 'admin', 
      breadCrumb: [
        {
          text: 'Emprendedores'
        }
      ]
    }
  },

  ]

  export default adminEntrepreneurs