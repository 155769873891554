var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"pt-8",attrs:{"headers":_vm.headers,"items":_vm.entrepreneurships,"search":_vm.search,"items-per-page":30,"footer-props":{
            'items-per-page-options': [10, 20, 30, 40, 50, 100],
            'items-per-page-text':'Evaluadores por página',
          },"mobile":"true"},scopedSlots:_vm._u([{key:"item.current_stage",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((item.current_stage=='pre_incubation')?'Pre incubación':((item.current_stage=='incubation')?'Incubación':'Pre Aceleración'))+" ")]}},{key:"item.progress",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.progress + '%')+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","to":{ name: 'showEntrepreneurships', params: { id: item.id } }}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1)]}}])})],1),_c('v-dialog',{attrs:{"overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_c('label',[_vm._v("Cargando...")]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }