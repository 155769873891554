<template>
  <v-row>
    <v-col cols="12" class="pt-2">
      <v-sheet
        width="100%"
        outlined 
        v-if="!loading" 
      >
        <v-row class="ma-4">
          <v-col cols="12">
            <label class="font-weight-regular text-uppercase">
              Emprendimiento:
            </label>
          </v-col>
          <v-col cols="12">
            <p class="font-weight-light text">
              {{ entrepreneurship.title  }}
            </p>
          </v-col>
        </v-row>

        <v-row class="ma-4">
          <v-col cols="12" xl="6" md="6" sm="12">
            <v-btn
              large
              block
              color="primary"
              :to="{name: 'showStartUpTutor'}">
              StartUp
            </v-btn>
          </v-col>

          <v-col cols="12" xl="6" md="6" sm="12">
            <v-btn
              large
              block
              color="primary"
              :to="{name: 'showStartUpTeamTutor' }">
              Integrantes
            </v-btn>
          </v-col>

          <v-col cols="12" xl="6" md="6" sm="12">
            <v-btn
              large
              block
              color="primary"
              :to="{name: 'showStartupWindowTutor'}">
              Vidriera
            </v-btn>
          </v-col>

          <v-col cols="12" xl="6" md="6" sm="12">
            <notes 
              :entrepreneurship_id="entrepreneurship.id" 
              :btn_block="true" 
            />
          </v-col>

        </v-row> 
      </v-sheet>
    </v-col>

    <v-col cols="12">
      <v-sheet
        width="100%"
        outlined 
      >
        <v-row class="pa-4">
          <v-col cols="12">
            <label class="font-weight-regular text-uppercase">
              Estadios:
            </label>
          </v-col>

          <v-col cols="12" xl="4" md="4" sm="12">
            <v-btn
              large
              block
              color="primary"
              :disabled="!entrepreneurship.pre_incubation"
              :to="{name: 'showPreIncubationStageTutor'}">
              Pre Incubación
            </v-btn>
          </v-col>

          <v-col cols="12" xl="4" md="4" sm="12">
            <v-btn
              large
              block
              color="primary"
              :disabled="!entrepreneurship.incubation"
              :to="{name: 'showIncubationStageTutor'}">
              Incubación
            </v-btn>
          </v-col>

          <v-col cols="12" xl="4" md="4" sm="12">
            <v-btn
              large
              block
              color="primary"
              :disabled="!entrepreneurship.pre_aceleration"
              :to="{name: 'showStartUpEvaluator'}">
              Pre Aceleración
            </v-btn>
          </v-col>

        </v-row> 
      </v-sheet>
    </v-col>

    <v-col cols="12" class="d-sm-flex pt-8">
      <v-btn
        :block="$vuetify.breakpoint.xsOnly"
        class="mr-auto mt-2"
        depressed
        color="primary"
        :to="{ name: 'EntrepreneurshipsAssignedTutor' }"
        >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Volver
      </v-btn>
    </v-col>

    <v-dialog
      v-model="loading"
      overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row> 
</template>
<script>
  import axios from 'axios'
  import notes from '@/components/notes/notes.vue'

  export default {
    name: 'EntrepreneurshipTasks',
    components: {
      notes
    },
    data: () => ({
      slug:null,
      loading:true,

      entrepreneurship:[],
      data:[],
    }),
    
    methods:{
      customSearch (value, search, item) {
          return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
      },

      async getEntrepreneurship(){
        await axios.get('tutor/entrepreneurships/'+this.slug).then((response) =>{
          this.data = response.data
          this.entrepreneurship = response.data.data.entrepreneurship

          this.loading=false;
        }).catch(error => {
          console.log(error);
        })
      }
    },

    created(){
      this.slug = this.$route.params.slug
      this.getEntrepreneurship()
    }
  }
</script>