<template>
  <v-row>
    <v-col cols="12">
      <v-bottom-navigation
        style="border-radius: 4px;"
        v-model="value"
        :background-color="color"
        dark
        shift
      >
        <v-btn>
          <span>Módulo</span>
          <v-icon>mdi-information</v-icon>
        </v-btn>

        <v-btn>
          <span>Pildoras</span>
          <v-icon>mdi-pill-multiple</v-icon>
        </v-btn>

        <v-btn v-show="module.require_activity">
          <span>Actividad</span>
          <v-icon>mdi-file-upload</v-icon>
        </v-btn>

        <v-btn v-show="module.entrepreneurships&&module.entrepreneurships.length>0">
          <span>Emprendimientos</span>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-col>

    <v-col cols="12" v-if="stage=='pre-incubation'">
      <v-sheet 
        v-if="(value===0)"
        outlined
        min-height="240">
        <infoModulePreIncubation :module="module" />
      </v-sheet>

      <v-sheet
        v-if="(value===1)"
        outlined
        min-height="240">
        <pillsModulePreIncubation :module="module" />
      </v-sheet>

      <v-sheet
        v-if="(value===2)"
        outlined
        min-height="240">
        <taskModulePreIncubation :module="module" />
      </v-sheet>

      <v-sheet
        v-if="(value===3)"
        outlined
        min-height="240">
        <entrepreneurshipsModulePreIncubation :module="module" />
      </v-sheet>
    </v-col>

    <v-col cols="12" v-if="stage=='incubation'">
      <v-sheet 
        v-if="(value===0)"
        outlined
        min-height="240">
        <infoModuleIncubation :module="module" />
      </v-sheet>

      <v-sheet
        v-if="(value===1)"
        outlined
        min-height="240">
        <pillsModuleIncubation :module="module" />
      </v-sheet>

      <v-sheet
        v-if="(value===2)"
        outlined
        min-height="240">
        <taskModuleIncubation :module="module" />
      </v-sheet>

      <v-sheet
        v-if="(value===3)"
        outlined
        min-height="240">
        <entrepreneurshipsModuleIncubation :module="module" />
      </v-sheet>
    </v-col>

  </v-row>
</template>

<script>
  import infoModulePreIncubation from '@/components/module/pre_incubation/infoModule.vue'
  import pillsModulePreIncubation from '@/components/module/pre_incubation/pillsModule.vue'
  import taskModulePreIncubation from '@/components/module/pre_incubation/taskModule.vue'
  import entrepreneurshipsModulePreIncubation from '@/components/module/pre_incubation/entrepreneurshipsModule.vue'

  import infoModuleIncubation from '@/components/module/incubation/infoModule.vue'
  import pillsModuleIncubation from '@/components/module/incubation/pillsModule.vue'
  import taskModuleIncubation from '@/components/module/incubation/taskModule.vue'
  import entrepreneurshipsModuleIncubation from '@/components/module/incubation/entrepreneurshipsModule.vue'

  export default {
    name: 'module',
    props: [ 'module', 'stage'],
    components: {
      infoModulePreIncubation, 
      pillsModulePreIncubation, 
      taskModulePreIncubation, 
      entrepreneurshipsModulePreIncubation,

      infoModuleIncubation, 
      pillsModuleIncubation, 
      taskModuleIncubation, 
      entrepreneurshipsModuleIncubation
    },
    data() {
      return {
        value: 0,
      }
    },
    computed: {
      color () {
        switch (this.value) {
          case 0: return '#00A1B8'
          case 1: return '#11778C'
          case 2: return '#23495C'
          case 3: return '#23495C'
          default: return '#00A1B8'
        }
      }
    }
  }
</script>
<style>
.video-incubation{
  max-width: 100%;
  z-index: 0;
  border: 2px solid #00a1b8;
  border-radius: 4px;
}
</style>